import 'core-js/stable' // ES6 polyfills
import 'regenerator-runtime/runtime' // more polyfills
import 'dom4' // DOM API polyfills

import 'unpoly/dist/unpoly.js'

let webpackContext = require.context('../javascripts', true, /\.js(\.erb)?$/)
for(let key of webpackContext.keys()) { webpackContext(key) }

// Images
require.context('../images', true, /\.(?:png|jpg|gif|ico|svg)$/)

// Stylesheets
import '../stylesheets'
