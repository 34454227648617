import loadStripe from '../services/load_stripe'

const elementStyles = {
  invalid: {
    color: '#000',
  }
}

up.compiler('[payment-form]', (element, { paymentMethod, stripeSubmitUrl, stripeRecheckUrl, stripeCompletedUrl, stripeAccountUrl }) => {
  let formEnabled = false
  let stripeField = null

  if (paymentMethod == 'credit_card') {
    element.ready = false
    element.readyPromise = loadStripe().then(async (stripe) => {
      element.stripe = stripe
      if (!document.contains(element)) return
      stripeField = await linkFormFields(element.stripe.elements())
      formEnabled = true

      element.addEventListener('submit', (event) => {
        event.preventDefault()
        onSubmit()
      })
      element.ready = true
    })
  } else {
    element.ready = true
    formEnabled = true
  }


  async function onSubmit() {
    if (!formEnabled) { return }
    if (!validateTermsAccepted()) { return }

    startProcessing()
    disableForm()
    try {
      await attemptPayment()
    } catch (error) {
      if (error && (error.type == 'card_error' || error.type == 'rate_limit_error' || error.type == 'validation_error' || error.type == 'invalid_request_error')) {
        enableForm()
      }
      if (error && error.message && (error.type == 'card_error' || error.type == 'validation_error')) {
        updateError(error)
      } else {
        // message might not be suitable to be shown to users
        updateError({ message: 'An unknown error occured processing your card.' })
      }
    }
    stopProcessing()
  }

  async function attemptPayment() {
    const { token, error } = await element.stripe.createToken(stripeField)
    if ( error ) {
      throw(error)
    } else {
      await attemptSubscriptionOnServer(token)
    }
  }

  async function attemptSubscriptionOnServer(token) {
    const formData = new FormData(element)
    formData.delete('_method')
    formData.set('order[stripe_token]', token.id)
    const { status, clientSecret, error } = await serverRequest(stripeSubmitUrl, { method: 'POST', params: formData })
    switch ( status ) {
      case 'paid':
        element.redirectTo(stripeCompletedUrl)
        break
      case 'setup_incomplete':
        await handleSCASetup(clientSecret)
        break
      case 'payment_incomplete':
        await handleSCAPayment(clientSecret)
        break
      case 'failed':
        throw(error)
      default:
        throw('subscription failed')
    }
  }

  async function handleSCAPayment(clientSecret) {
    // SCA = Strong Customer Authentication
    // for EU customers, sometimes an additional authentication step is required (e.g. 3D Secure)
    // in this case a payment was attempted, but requires additional authorization
    const { error } = await element.stripe.handleCardPayment(clientSecret, stripeField)
    if (error) {
      throw(error)
    } else {
      await recheckSubscriptionOnServer('An unexpected error occured confirming your subscription. If you subscription does not become active, please contact us.')
    }
  }

  async function handleSCASetup(clientSecret) {
    // SCA = Strong Customer Authentication
    // for EU customers, sometimes an additional authentication step is required (e.g. 3D Secure)
    // in this case NO payment was attempted, but Stripe forsees additional authorization would be
    // required upon the next charge
    const { error } = await element.stripe.handleCardSetup(clientSecret)
    if (error) {
      throw(error)
    } else {
      await recheckSubscriptionOnServer('An unexpected error occured authorizing your credit card. It has been updated, but we might have to contact you again if the next payment fails.')
    }
  }

  async function recheckSubscriptionOnServer(failureMessage) {
    const { status } = await serverRequest(stripeRecheckUrl, { method: 'POST' })
    switch (status) {
      case 'paid':
        element.redirectTo(stripeCompletedUrl)
        break
      default:
        alert(failureMessage)
        element.redirectTo(stripeAccountUrl)
    }
  }

  async function serverRequest(url, options) {
    const response = await up.request(url, options)
    if (response.status == 200) {
      const responseData = JSON.parse(response.text)
      if (responseData.error) {
        throw(responseData.error)
      } else {
        return responseData
      }
    } else {
      throw('unknown server error')
    }
  }

  function redirectTo(redirectUrl) {
    window.location.href = redirectUrl
  }

  function updateError(error) {
    const errorContainer = document.getElementById('stripe_error')
    if ( error ) {
      errorContainer.textContent = error.message
    } else {
      errorContainer.textContent = ' '
    }
  }

  function errorListener({ error }) {
    updateError(error)
  }

  async function linkFormFields(elements) {
    const cardNumber = elements.create('cardNumber', {
      style: elementStyles,
      placeholder: '',
    })
    cardNumber.mount('#stripe_card_number')
    cardNumber.addEventListener('change', errorListener)

    const cardExpiry = elements.create('cardExpiry', {
      style: elementStyles,
    })
    cardExpiry.mount('#stripe_card_expiry')
    cardNumber.addEventListener('change', errorListener)

    const cardCvc = elements.create('cardCvc', {
      style: elementStyles,
      placeholder: '',
    })
    cardCvc.mount('#stripe_card_cvc')
    cardNumber.addEventListener('change', errorListener)

    await Promise.all([
      waitForReady(cardNumber),
      waitForReady(cardExpiry),
      waitForReady(cardCvc),
    ])

    return cardNumber
  }

  function waitForReady(element) {
    return new Promise((resolve) => {
      element.on('ready', resolve)
    })
  }

  function enableForm() {
    formEnabled = true
    findButtons().forEach((button) => {
      button.disabled = false
    })
  }

  function disableForm() {
    formEnabled = false
    findButtons().forEach((button) => {
      button.disabled = true
    })
  }

  function findButtons() {
    return element.querySelectorAll('input[type="submit"], button')
  }

  function startProcessing() {
    findProcessingIndicator().classList.remove('hidden')
  }

  function stopProcessing() {
    findProcessingIndicator().classList.add('hidden')
  }

  function findProcessingIndicator() {
    return element.querySelector('.progress-indicator')
  }

  function validateTermsAccepted() {
    const checkbox = element.querySelector('[type="checkbox"][name="order[accepted_terms_and_privacy_policy]"]')
    if (checkbox) {
      const container = checkbox.closest('.controls')
      if (checkbox.checked) {
        container.classList.remove('field_with_errors')
        return true
      } else {
        container.classList.add('field_with_errors')
        return false
      }
    } else {
      return true
    }
  }


  // expose some methods for testing
  element.onSubmit = onSubmit
  element.redirectTo = redirectTo

  return
})
