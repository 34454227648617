up.on('click', '[smooth-scroll]', (event, element) => {
  if ( element.pathname === location.pathname ) {
    const hash = up.util.presence(element.hash) || '#top'
    const targetAnchor = document.querySelector(hash)
    if ( targetAnchor ) {
      targetAnchor.scrollIntoView({ behavior: 'smooth' })
      event.preventDefault()
    }
  }
})
