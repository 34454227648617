up.compiler('.action[up-close]', (element) => {
  let text
  if ( element.closest('.up-modal') ) {
    text = 'Close'
  } else {
    text = 'Back'
  }
  element.text = text
});


