up.compiler('.alternatives--button', (element) => {
  const update = () => {
    if ( element.checked ) {
      document.getElementsByName(element.name).forEach((otherElement) => {
        up.element.closest(otherElement, '.alternatives--label').classList.remove('-checked')
      })
      up.element.closest(element, '.alternatives--label').classList.add('-checked')
    }
  }

  element.addEventListener('click', () => {
    update()
  })

  update()
})
