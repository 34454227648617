// periodically reload a fragment
up.compiler('[auto-refresh]', (element, { target, source }) => {
  async function update() {
    let targetElement = document.querySelector(target)
    let response = await up.request(source, { cache: false })
    // we take some pains to only replace the element if it actually changed
    let newDocument = new DOMParser().parseFromString(response.text, 'text/html')
    let newElement = newDocument.querySelector(target)
    if (newElement && targetElement && newElement.outerHTML != targetElement.outerHTML) {
      up.extract(target, response.text)
    } else {
      setTimeout(update, 2000)
    }
  }
  setTimeout(update, 2000)
})
