function trackUrl(url) {
  let anchor = document.createElement('a')
  anchor.href = url
  _paq.push(['setCustomUrl', anchor.href])
  _paq.push(['trackPageView'])
}

up.compiler('[track-page-view]', (element) => {
  const url = element.getAttribute('track-page-view')
  trackUrl(url)
})

up.compiler('[track-click]', (element) => {
  const url = element.getAttribute('track-click')
  element.addEventListener('click', (event) => {
    trackUrl(url)
  })
})
