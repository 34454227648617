import scriptLoader from 'promise-script-loader'
import Settings from './settings'

let stripe = undefined

function initStripe() {
  return window.Stripe(Settings.get().stripe.public_key, {
    locale: 'en',
  })
}

export default function loadStripe() {
  if ( window.Stripe ) {
    return Promise.resolve(initStripe())
  } else {
    return scriptLoader('https://js.stripe.com/v3/').then(() => {
      return initStripe()
    })
  }
}
