let settings = {}

export default class Settings {
  static set(new_settings) {
    settings = new_settings
  }

  static get() {
    return settings
  }
}

window.Settings = Settings
